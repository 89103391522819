import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4db2e0b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "material-icons-round leading-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.hidden)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(["md-chip input", [
      _ctx.variant,
      _ctx.icon ? 'icon' : '',
      _ctx.avatar ? 'avatar' : '',
      _ctx.closable ? 'closable' : '',
    ]]),
        disabled: _ctx.disabled
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("i", _hoisted_2, _toDisplayString(_ctx.icon), 1))
          : (_ctx.avatar)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "image",
                style: _normalizeStyle({ 'background-image': 'url(' + _ctx.avatar + ')' })
              }, null, 4))
            : _createCommentVNode("", true),
        _createElementVNode("span", null, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        (_ctx.closable)
          ? (_openBlock(), _createElementBlock("i", {
              key: 2,
              class: "material-icons-round trailing-icon",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, "close"))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}