
import { defineComponent } from "vue";
import MdChipMixin from "./MdChipMixin";

export default defineComponent({
  name: "MdInputChip",
  mixins: [MdChipMixin],
  props: {
    icon: {
      type: String,
      required: false,
    },
    avatar: {
      type: String,
      required: false,
    },
    closable: Boolean,
  },
  data() {
    return {
      hidden: false,
    };
  },
  emits: ["close"],
  methods: {
    close() {
      if (this.disabled) return;
      this.$emit("close");
      this.hidden = true;
    },
  },
});
