import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f15ca3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delete-modal-user-preview" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_avatar = _resolveComponent("md-avatar")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_modal = _resolveComponent("md-modal")!

  return (_openBlock(), _createBlock(_component_md_modal, {
    ref: "modal",
    title: "Benutzer löschen?",
    icon: "delete_forever",
    variant: "delete",
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Wenn du diesen Benutzer löscht, verliert er den Zugriff auf diese Organisation und alle Inhalte darin. Außerdem kann er sich nicht mehr anmelden und auf ChatCaptain Dienste zugreifen. Du kannst diesen Benutzer zu jederzeit erneut zu dieser Organisation einladen. ")
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.user?.image)
          ? (_openBlock(), _createBlock(_component_md_avatar, {
              key: 0,
              variant: "image",
              size: "large",
              content: _ctx.user?.image
            }, null, 8, ["content"]))
          : _createCommentVNode("", true),
        _createElementVNode("h2", null, _toDisplayString(_ctx.user?.name), 1),
        _createElementVNode("h4", null, _toDisplayString(_ctx.user?.email), 1)
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_md_button, {
        variant: "text",
        grey: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Abbrechen")
        ]),
        _: 1
      }),
      _createVNode(_component_md_button, {
        variant: "filled",
        mode: "delete",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submit')))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Benutzer löschen")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading"]))
}