
import { defineComponent } from "vue";
import { MdOverlayContentMixin } from "@/components/md/MdOverlay";
import { MdButton } from "@/components/md/MdButton";
import { Stepper, Step, IStepper } from "@/components/cc/Stepper";
import { MdInputField, MdSelect } from "@/components/md/MdInput";
import { IMenuItem } from "@/components/md/MdMenu";
import { MdTabsInline, MdTab } from "@/components/md/MdTabs";
import { InputValidator, InputType } from "@/classes/InputValidator";
import {
  loadStripe,
  SetupIntentResult,
  Stripe,
  StripeCardElement,
  StripeIbanElement,
} from "@stripe/stripe-js";
import {
  createPaymentMethod,
  getOrganizationId,
  getSetupIntent,
} from "@/api/admin/billing";
import { useStore } from "@/store";
import { AdminStore, RootStore } from "@/store/constants";

export default defineComponent({
  name: "BillingAddPaymentMethodOverlay",
  mixins: [MdOverlayContentMixin],
  components: {
    MdButton,
    Stepper,
    Step,
    MdInputField,
    MdSelect,
    MdTabsInline,
    MdTab,
  },
  data() {
    return {
      buttonLabel: "Weiter",
      buttonActive: false,
      step: 1,
      type: "card",
      company: "",
      address: {
        street: "",
        postcode: "",
        city: "",
        country: "DE",
      },
      holderName: "",
      holderEmail: "",
      vatId: "",
      countries: [
        {
          label: "Deutschland",
          value: "DE",
        },
        {
          label: "Österreich",
          value: "AT",
        },
        {
          label: "Schweiz",
          value: "CH",
        },
      ] as IMenuItem[],
      stripe: null as Stripe | null,
      card: undefined as StripeCardElement | undefined,
      iban: undefined as StripeIbanElement | undefined,
    };
  },
  setup() {
    getOrganizationId();
    const store = useStore();
    return { store };
  },
  mounted() {
    loadStripe("pk_test_1wF4Dh1JaYPCwwjYqyAqgaXi").then((stripe) => {
      this.stripe = stripe;
      this.card = stripe?.elements().create("card", {
        style: {
          base: {
            fontFamily: "Roboto",
            fontSize: "16px",
          },
        },
      });
      this.card?.mount(this.$refs.cardWrapper as HTMLElement);
      this.iban = stripe?.elements().create("iban", {
        supportedCountries: ["SEPA"],
        style: {
          base: {
            fontFamily: "Roboto",
            fontSize: "16px",
          },
        },
      });
      this.iban?.mount(this.$refs.ibanWrapper as HTMLElement);
    });
  },
  computed: {
    stepper(): IStepper {
      return this.$refs.stepper as IStepper;
    },
    vatIdRequired(): boolean {
      return this.address.country != "DE";
    },
    addressValidators(): { [key: string]: InputValidator } {
      return {
        company: new InputValidator(this.company, InputType.TEXT, true),
        street: new InputValidator(this.address.street, InputType.TEXT, true),
        postcode: new InputValidator(
          this.address.postcode,
          InputType.TEXT,
          true
        ),
        city: new InputValidator(this.address.city, InputType.TEXT, true),
        country: new InputValidator(this.address.country, InputType.TEXT, true),
        vatId: new InputValidator(
          this.vatId,
          InputType.TEXT,
          this.vatIdRequired
        ),
      };
    },
    addressInputFields(): typeof MdInputField[] {
      return [
        this.$refs.companyInput as typeof MdInputField,
        this.$refs.streetInput as typeof MdInputField,
        this.$refs.postcodeInput as typeof MdInputField,
        this.$refs.cityInput as typeof MdInputField,
        this.$refs.countryInput as typeof MdInputField,
        this.$refs.vatIdInput as typeof MdInputField,
      ];
    },
    holderNameValidator(): InputValidator {
      return new InputValidator(this.holderName, InputType.TEXT, true);
    },
    holderNameCardInput(): typeof MdInputField {
      return this.$refs.holderNameCardInput as typeof MdInputField;
    },
    holderNameSepaInput(): typeof MdInputField {
      return this.$refs.holderNameSepaInput as typeof MdInputField;
    },
    holderEmailValidator(): InputValidator {
      return new InputValidator(this.holderEmail, InputType.EMAIL, true);
    },
    holderEmailInput(): typeof MdInputField {
      return this.$refs.holderEmailInput as typeof MdInputField;
    },
  },
  methods: {
    onStepperChange(step: number) {
      this.step = step + 1;
    },
    onTypeChange(type: string, event: boolean) {
      if (!event) return;
      this.type = type;
    },
    goToNextStep() {
      if (this.step == 1) {
        this.validateInputFields();
      } else {
        this.createMethod();
      }
    },
    validateInputFields() {
      this.addressInputFields.forEach((input) => {
        input.validate();
      });
      const valid = Object.values(this.addressValidators).every((validator) =>
        validator.isValid()
      );
      if (valid) this.stepper.next();
    },
    async createMethod() {
      if (this.type == "card") {
        this.holderNameCardInput.validate();
        if (!this.holderNameValidator.isValid()) return;
      } else {
        this.holderNameSepaInput.validate();
        this.holderEmailInput.validate();
        if (
          !this.holderNameValidator.isValid() ||
          !this.holderEmailValidator.isValid()
        )
          return;
      }
      this.setLoading(true);
      const setupIntentSecret = await getSetupIntent();
      let intentResult: SetupIntentResult | undefined;
      switch (this.type) {
        case "card":
          if (this.card) {
            intentResult = await this.stripe?.confirmCardSetup(
              setupIntentSecret,
              {
                payment_method: {
                  card: this.card,
                },
              }
            );
          } else {
            this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
              state: "error",
              content: "Karte konnte nicht hinzugefügt werden",
            });
            this.setLoading(false);
            return;
          }
          break;
        case "sepa_debit":
          if (this.iban) {
            intentResult = await this.stripe?.confirmSepaDebitSetup(
              setupIntentSecret,
              {
                payment_method: {
                  sepa_debit: this.iban,
                  billing_details: {
                    name: this.holderName,
                    email: this.holderEmail,
                  },
                },
              }
            );
          } else {
            this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
              state: "error",
              content: "Bankkonto konnte nicht hinzugefügt werden",
            });
            this.setLoading(false);
            return;
          }
          break;
      }
      console.log("intent result: ", intentResult?.setupIntent);
      if (intentResult?.setupIntent?.payment_method) {
        await createPaymentMethod(
          intentResult?.setupIntent?.payment_method as string,
          this.company,
          this.address,
          this.holderName,
          this.vatId
        );
      }
      this.setLoading(false);
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content:
          (this.type == "card" ? "Kredit- oder Debitkarte" : "Bankkonto") +
          " hinzugefügt",
      });
      this.close();
      this.complete();
    },
  },
});
