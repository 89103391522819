
import { defineComponent } from "vue";
import { MdTabs } from "@/components/md/MdTabs";
import { MdCard } from "@/components/md/MdCard";
import { MdAvatar } from "@/components/md/MdAvatar";
import { MdButton } from "@/components/md/MdButton";
import {
  MdTable,
  MdTableColumn,
  IRowButton,
  IRow,
} from "@/components/md/MdTable";
import { useStore } from "vuex";
import { IGroupResult, IUserResult } from "@chatcaptain/types/organization";
import { deleteUser, getGroupsByUser, updateUser } from "@/api/admin/users";
import { AdminStore, RootStore } from "@/store/constants";
import { EmptyHint } from "@/components/cc/EmptyHint";
import UserCardLoader from "@/components/admin/UserCardLoader.vue";
import { IMdModal } from "@/components/md/MdDialog";
import UserDeleteModal from "@/components/admin/UserDeleteModal.vue";
import UserAddGroupModal from "@/components/admin/UserAddGroupModal.vue";
import UserRemoveFromGroupModal from "@/components/admin/UserRemoveFromGroupModal.vue";
import UpdatePermissionsModal from "@/components/admin/UpdatePermissionsModal.vue";

export default defineComponent({
  name: "AdminUser",
  components: {
    MdTabs,
    MdCard,
    MdAvatar,
    MdButton,
    MdTable,
    MdTableColumn,
    UserCardLoader,
    EmptyHint,
    UserDeleteModal,
    UserAddGroupModal,
    UserRemoveFromGroupModal,
    UpdatePermissionsModal,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      isLoading: true,
      isGroupsLoading: true,
      groups: [] as IRow[],
      removeButton: {
        icon: "remove_circle_outline",
        tooltip: "Gruppe entfernen",
      } as IRowButton,
      isDeleteModalLoading: false,
      targetGroup: {},
    };
  },
  async mounted() {
    this.loadGroups();
  },
  computed: {
    userId(): string {
      return this.$route.params?.userId as string;
    },
    user(): IUserResult | null {
      return this.store.getters[AdminStore.Getters.GET_USER](this.userId);
    },
    sortedPermissions(): string[] | undefined {
      const permissions = this.user?.permissions;
      return permissions?.sort((a, b) => {
        return (
          this.store.getters[AdminStore.Getters.GET_PERMISSION_ORDER](a) -
          this.store.getters[AdminStore.Getters.GET_PERMISSION_ORDER](b)
        );
      });
    },
    permissions(): IRow[] {
      const permissions: IRow[] = [];
      if (this.user) {
        this.sortedPermissions?.forEach((permission) => {
          permissions.push({
            raw: permission,
            readable:
              this.store.getters[AdminStore.Getters.GET_PERMISSION_NAME](
                permission
              ),
          });
        });
      }
      return permissions;
    },
    userPermissions(): string[] {
      return this.user?.user_permissions || [];
    },
    deleteModal(): IMdModal {
      return this.$refs.deleteModal as IMdModal;
    },
    addGroupModal(): IMdModal {
      return this.$refs.addGroupModal as IMdModal;
    },
    updatePermissionsModal(): IMdModal {
      return this.$refs.updatePermissionsModal as IMdModal;
    },
  },
  watch: {
    user(newValue) {
      if (!this.$route.params.userId) return;
      this.isLoading = false;
      if (!newValue) {
        this.$router.push({
          path: "/admin/users",
        });
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Benutzer nicht gefunden",
        });
      }
    },
  },
  methods: {
    formatedDateTime(date: Date): string | null {
      if (!date) return null;
      return this.formatDate(date) + " um " + this.formatTime(date);
    },
    async deleteUser() {
      this.isDeleteModalLoading = true;
      await deleteUser(this.userId);
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content: "Benutzer gelöscht",
      });
      this.$router.push({
        path: "/admin/users",
      });
    },
    async loadGroups() {
      this.groups = [];
      this.isGroupsLoading = true;
      const groupsRaw = await getGroupsByUser(this.userId);
      const groups: IRow[] = [];
      groupsRaw?.forEach((group: IGroupResult) => {
        groups.push({
          id: group.id,
          name: group.name,
          remove: this.removeButton,
          group: group,
        });
      });
      this.isGroupsLoading = false;
      this.groups = groups;
    },
    updatePermissions() {
      this.store.dispatch(AdminStore.Actions.UPDATE_USER, this.userId);
    },
    updateGroupsAndPermissions() {
      this.loadGroups();
      this.updatePermissions();
    },
    handleGroupsTableClick(columnName: string, row: IRow, index: number) {
      if (columnName != "remove") return;
      this.targetGroup = row.group;
      (this.$refs.removeFromGroupModal as IMdModal).open();
    },
    async setPermissions(permissions: string[]) {
      this.updatePermissionsModal.setLoadingState(true);
      await updateUser(this.userId, permissions);
      this.updatePermissions();
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content: "Berechtigungen aktualisiert",
      });
      this.updatePermissionsModal.setLoadingState(false);
      this.updatePermissionsModal.close();
    },
    redirectToGroup(row: IRow) {
      this.$router.push({
        path: "/admin/groups/" + row.id,
      });
    },
  },
});
