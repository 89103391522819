
import { defineComponent } from "vue";
import { EmptyHint } from "@/components/cc/EmptyHint";
import {
  IRow,
  IRowBadge,
  MdTable,
  MdTableColumn,
  RowBadgeTypes,
} from "@/components/md/MdTable";
import { useStore } from "@/store";
import { IInvoiceResult } from "@chatcaptain/types/billing";
import { AdminStore } from "@/store/constants";
import { getInvoiceDownloadUrl } from "@/api/admin/billing";

export default defineComponent({
  name: "AdminBillingInvoices",
  components: {
    EmptyHint,
    MdTable,
    MdTableColumn,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      downloadButton: {
        icon: "download",
        tooltip: "PDF",
      },
      badgeByStatus: {
        paid: {
          state: "success",
          text: "Bezahlt",
        },
        draft: {
          state: "info",
          text: "Entwurf",
        },
        void: {
          state: "neutral",
          text: "Storniert",
        },
        open: {
          state: "warning",
          text: "Offen",
          icon: true,
        },
        uncollectible: {
          state: "error",
          text: "Fehlgeschlagen",
          icon: true,
        },
      } as { [key: string]: IRowBadge },
      currentlyLoading: [] as number[],
    };
  },
  mounted() {
    this.store.dispatch(AdminStore.Actions.LOAD_INVOICES);
  },
  computed: {
    invoices(): IInvoiceResult[] {
      return this.store.getters[AdminStore.Getters.GET_INVOICES];
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      this.invoices.forEach((invoice, index) => {
        rows.push({
          id: invoice.id,
          number: invoice.number,
          amount: this.getPrice(invoice.total) + " €",
          created: this.getDate(new Date(invoice.created)),
          status: this.badgeByStatus[invoice.status],
          download: this.downloadButton,
          _loading: this.currentlyLoading.includes(index),
        });
      });
      return rows;
    },
  },
  methods: {
    getDate(date: Date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)) +
        "." +
        date.getFullYear()
      );
    },
    getPrice(price: number): string {
      return (price / 100)
        .toFixed(2)
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async downloadInvoice(columnName: string, row: IRow, index: number) {
      this.currentlyLoading.push(index);
      const url = await getInvoiceDownloadUrl(row.id);
      const i = this.currentlyLoading.findIndex((el) => el == index);
      this.currentlyLoading.splice(i, 1);
      window.open(url);
    },
  },
});
