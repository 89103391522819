
import { defineComponent } from "vue";
import { useStore } from "@/store";
import { UserStore } from "@/store/constants";
import OrganizationAddOverlay from "@/components/admin/OrganizationAddOverlay.vue";
import { IMdOverlay } from "@/components/md/MdOverlay";
import { MdTabs } from "@/components/md/MdTabs";

interface IDashboardItem {
  icon: string;
  title: string;
  description: string;
  path: string;
  disabled?: boolean;
}

export default defineComponent({
  name: "AdminDashboard",
  components: { MdTabs, OrganizationAddOverlay },
  setup() {
    return { store: useStore() };
  },
  data() {
    return {
      items: [
        {
          icon: "person",
          title: "Benutzer",
          description: "",
          path: "/users",
          disabled: false,
        },
        {
          icon: "group",
          title: "Gruppen",
          description: "",
          path: "/groups",
          disabled: false,
        },
        {
          icon: "memory",
          title: "Chatbots",
          description: "",
          path: "/chatbots",
          disabled: false,
        },
        {
          icon: "payment",
          title: "Abrechnung",
          description: "",
          path: "/billing",
          disabled: true,
        },
      ] as IDashboardItem[],
    };
  },
  computed: {
    disabledItems(): IDashboardItem[] {
      return this.items.filter((item) => item.disabled);
    },
    activeItems(): IDashboardItem[] {
      return this.items.filter((item) => !item.disabled);
    },
    organizationAddOverlay(): IMdOverlay {
      return this.$refs.organizationAddOverlay as IMdOverlay;
    },
  },
  methods: {
    onItemClick(item: IDashboardItem) {
      if (item.disabled) {
        return;
      }
      this.$router.push("/admin" + item.path);
    },
  },
  mounted() {
    const orgId = this.store.getters[UserStore.Getters.ORGANIZATION_ID];
    if (!orgId) this.organizationAddOverlay.open();
  },
});
