
import { defineComponent } from "vue";
import MdChipMixin from "./MdChipMixin";

export default defineComponent({
  name: "MdSuggestionChip",
  mixins: [MdChipMixin],
  props: {
    icon: {
      type: String,
      required: false,
    },
  },
});
