import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4793a9a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "groups" }
const _hoisted_2 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_tabs = _resolveComponent("md-tabs")!
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_empty_hint = _resolveComponent("empty-hint")!
  const _component_group_delete_modal = _resolveComponent("group-delete-modal")!
  const _component_group_add_member_modal = _resolveComponent("group-add-member-modal")!
  const _component_md_input_field = _resolveComponent("md-input-field")!
  const _component_md_modal = _resolveComponent("md-modal")!
  const _component_md_fab_extended = _resolveComponent("md-fab-extended")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_tabs, {
      title: "Gruppen",
      "title-only": ""
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoading || (_ctx.rows && _ctx.rows.length > 0))
        ? (_openBlock(), _createBlock(_component_md_table, {
            key: 0,
            rows: _ctx.rows,
            clickable: true,
            onClick: _ctx.redirectToGroup,
            onButtonClick: _ctx.handleTableButtonClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_table_column, {
                title: "Name",
                display: "name"
              }),
              _createVNode(_component_md_table_column, {
                title: "Beschreibung",
                display: "description"
              }),
              _createVNode(_component_md_table_column, {
                title: "Mitglieder",
                display: "members",
                numeric: true
              }),
              _createVNode(_component_md_table_column, {
                title: "Berechtigungen",
                display: "permissions",
                numeric: true
              }),
              _createVNode(_component_md_table_column, {
                type: "button",
                display: "add_member"
              }),
              _createVNode(_component_md_table_column, {
                type: "button",
                display: "delete"
              })
            ]),
            _: 1
          }, 8, ["rows", "onClick", "onButtonClick"]))
        : (_openBlock(), _createBlock(_component_empty_hint, {
            key: 1,
            title: "Keine Gruppen gefunden",
            description: "Erstelle deine erste Gruppe, damit du Benutzer und Berechtigungen noch einfach verwalten kannst."
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_button, {
                variant: "filled",
                icon: "add_circle",
                onClick: _ctx.openCreateModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Gruppe erstellen")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }))
    ]),
    _createVNode(_component_group_delete_modal, {
      ref: "deleteModal",
      group: _ctx.target.group,
      onSubmit: _ctx.deleteGroup
    }, null, 8, ["group", "onSubmit"]),
    _createVNode(_component_group_add_member_modal, {
      ref: "addMemberModal",
      group: _ctx.target.group
    }, null, 8, ["group"]),
    _createVNode(_component_md_modal, {
      ref: "createModal",
      title: "Neue Gruppe erstellen",
      "no-divider": "",
      loading: _ctx.createModalLoading,
      onSubmit: _ctx.createGroup
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Erstelle eine neue Gruppe um in dieser Benutzer und Berechtigungen einfacher zu verwalten. Außerdem kannst du den Zugriff auf Chatbots für alle Mitglieder einer Gruppe steuern. ")
      ]),
      body: _withCtx(() => [
        _createVNode(_component_md_input_field, {
          modelValue: _ctx.newGroupName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newGroupName) = $event)),
          "input-validator": _ctx.newGroupNameValidator,
          ref: "newGroupName",
          label: "Name der Gruppe",
          variant: "filled"
        }, null, 8, ["modelValue", "input-validator"]),
        _createVNode(_component_md_input_field, {
          modelValue: _ctx.newGroupDescription,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newGroupDescription) = $event)),
          label: "Beschreibung der Gruppe (optional)",
          variant: "filled"
        }, null, 8, ["modelValue"])
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_md_button, {
          variant: "text",
          grey: "",
          onClick: _ctx.closeCreateModal
        }, {
          default: _withCtx(() => [
            _createTextVNode("Abbrechen")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_md_button, {
          variant: "filled",
          onClick: _ctx.createGroup
        }, {
          default: _withCtx(() => [
            _createTextVNode("Neue Gruppe erstellen")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["loading", "onSubmit"]),
    (_ctx.isLoading || (_ctx.rows && _ctx.rows.length > 0))
      ? (_openBlock(), _createBlock(_component_md_fab_extended, {
          key: 0,
          icon: "add_circle",
          onClick: _ctx.openCreateModal
        }, {
          default: _withCtx(() => [
            _createTextVNode("Gruppe erstellen")
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}