import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44a9dd00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delete-method-modal-preview" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_modal = _resolveComponent("md-modal")!

  return (_openBlock(), _createBlock(_component_md_modal, {
    ref: "modal",
    title: "Zahlungsmethode wirklich löschen?",
    icon: "cancel",
    variant: "delete"
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Wenn du diese Zahlungsmethode löscht, kann sie nicht mehr verwendet werden. Wir werden diese Zahlungsmethode in Zukunft nicht mehr belasten. ")
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.method.card
              ? "Kredit- oder Debitkarte"
              : "SEPA Lastschriftverfahren"), 1),
        _createElementVNode("h4", null, "Endet auf " + _toDisplayString(_ctx.method.card?.last4 || _ctx.method.sepa?.last4), 1)
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_md_button, {
        variant: "text",
        grey: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Abbrechen")
        ]),
        _: 1
      }),
      _createVNode(_component_md_button, {
        variant: "filled",
        mode: "delete",
        onClick: _ctx.deleteMethod
      }, {
        default: _withCtx(() => [
          _createTextVNode("Zahlungsmethode löschen")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 512))
}