
import { defineComponent } from "vue";
import { MdTabs } from "@/components/md/MdTabs";
import { MdCard } from "@/components/md/MdCard";
import { MdButton } from "@/components/md/MdButton";
import {
  MdTable,
  MdTableColumn,
  IRowButton,
  IRow,
} from "@/components/md/MdTable";
import { useStore } from "vuex";
import { IGroupResult, IUserResult } from "@chatcaptain/types/organization";
import { AdminStore, RootStore } from "@/store/constants";
import { EmptyHint } from "@/components/cc/EmptyHint";
import GroupCardLoader from "@/components/admin/GroupCardLoader.vue";
import { IMdModal } from "@/components/md/MdDialog";
import UserRemoveFromGroupModal from "@/components/admin/UserRemoveFromGroupModal.vue";
import UpdatePermissionsModal from "@/components/admin/UpdatePermissionsModal.vue";
import { deleteGroup, getMembers, updateGroup } from "@/api/admin/groups";
import GroupDeleteModal from "@/components/admin/GroupDeleteModal.vue";
import GroupAddMemberModal from "@/components/admin/GroupAddMemberModal.vue";

export default defineComponent({
  name: "AdminGroup",
  components: {
    MdTabs,
    MdCard,
    MdButton,
    MdTable,
    MdTableColumn,
    GroupCardLoader,
    EmptyHint,
    UserRemoveFromGroupModal,
    UpdatePermissionsModal,
    GroupDeleteModal,
    GroupAddMemberModal,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      isLoading: true,
      isMembersLoading: true,
      members: [] as IRow[],
      removeButton: {
        icon: "remove_circle_outline",
        tooltip: "Benutzer entfernen",
      } as IRowButton,
      isDeleteModalLoading: false,
      targetMember: {},
    };
  },
  async mounted() {
    this.loadMembers();
  },
  computed: {
    groupId(): string {
      return this.$route.params?.groupId as string;
    },
    group(): IGroupResult | undefined {
      return this.store.getters[AdminStore.Getters.GET_GROUP](this.groupId);
    },
    rawPermissions(): string[] {
      return this.group?.permissions || [];
    },
    sortedPermissions(): string[] | undefined {
      const permissions = this.group?.permissions || [];
      return permissions?.sort((a, b) => {
        return (
          this.store.getters[AdminStore.Getters.GET_PERMISSION_ORDER](a) -
          this.store.getters[AdminStore.Getters.GET_PERMISSION_ORDER](b)
        );
      });
    },
    permissions(): IRow[] {
      const permissions: IRow[] = [];
      if (this.group) {
        this.sortedPermissions?.forEach((permission) => {
          permissions.push({
            raw: permission,
            readable:
              this.store.getters[AdminStore.Getters.GET_PERMISSION_NAME](
                permission
              ),
          });
        });
      }
      return permissions;
    },
    deleteModal(): IMdModal {
      return this.$refs.deleteModal as IMdModal;
    },
    addMemberModal(): IMdModal {
      return this.$refs.addMemberModal as IMdModal;
    },
    removeMemberModal(): IMdModal {
      return this.$refs.removeMemberModal as IMdModal;
    },
    updatePermissionsModal(): IMdModal {
      return this.$refs.updatePermissionsModal as IMdModal;
    },
  },
  watch: {
    group(newValue) {
      if (!this.$route.params.groupId) return;
      this.isLoading = false;
      if (!newValue) {
        this.$router.push({
          path: "/admin/groups",
        });
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Gruppe nicht gefunden",
        });
      }
    },
  },
  methods: {
    formatedDateTime(date: Date): string | null {
      if (!date) return null;
      return this.formatDate(date) + " um " + this.formatTime(date);
    },
    async deleteGroup() {
      this.deleteModal.setLoadingState(true);
      await deleteGroup(this.groupId);
      this.deleteModal.setLoadingState(false);
      this.deleteModal.close();
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content: "Gruppe " + this.group?.name + " gelöscht",
      });
      this.$router.push({
        path: "/admin/groups",
      });
    },
    async loadMembers() {
      this.members = [];
      this.isMembersLoading = true;
      const membersRaw = await getMembers(this.groupId);
      const members: IRow[] = [];
      membersRaw?.forEach((member: IUserResult) => {
        members.push({
          id: member.id,
          image: member.image,
          name: member.name,
          remove: this.removeButton,
          user: member,
        });
      });
      this.isMembersLoading = false;
      this.members = members;
    },
    updatePermissions() {
      this.store.dispatch(AdminStore.Actions.UPDATE_GROUP, this.groupId);
    },
    handleGroupsTableClick(columnName: string, row: IRow, index: number) {
      if (columnName != "remove") return;
      this.targetMember = row.user;
      this.removeMemberModal.open();
    },
    async setPermissions(permissions: string[]) {
      console.log(permissions);
      this.updatePermissionsModal.setLoadingState(true);
      await updateGroup(this.groupId, permissions);
      this.updatePermissions();
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content: "Berechtigungen aktualisiert",
      });
      this.updatePermissionsModal.setLoadingState(false);
      this.updatePermissionsModal.close();
    },
    redirectToUser(row: IRow) {
      this.$router.push({
        path: "/admin/users/" + row.id,
      });
    },
  },
});
