
import { defineComponent } from "vue";
import { MdTabs } from "@/components/md/MdTabs";
import {
  MdTable,
  MdTableColumn,
  IRowButton,
  IRow,
} from "@/components/md/MdTable";
import { getOrganizationId, deleteUser } from "@/api/admin/users";
import { IUserResult } from "@chatcaptain/types/organization";
import { IMdModal } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { MdFabExtended } from "@/components/md/MdFab";
import UsersInviteOverlay from "../../components/admin/UsersInviteOverlay.vue";
import { useStore } from "@/store";
import { AdminStore, RootStore } from "@/store/constants";
import { EmptyHint } from "@/components/cc/EmptyHint";
import DeleteUserModal from "@/components/admin/UserDeleteModal.vue";
import UserAddGroupModal from "@/components/admin/UserAddGroupModal.vue";
import { IMdOverlay } from "@/components/md/MdOverlay";

export default defineComponent({
  name: "AdminUsers",
  components: {
    MdTabs,
    MdTable,
    MdTableColumn,
    MdButton,
    MdFabExtended,
    UsersInviteOverlay,
    EmptyHint,
    DeleteUserModal,
    UserAddGroupModal,
  },
  props: {
    selectable: Boolean,
  },
  setup() {
    return { store: useStore() };
  },
  data() {
    return {
      addToGroupButton: {
        icon: "group_add",
        tooltip: "Zu Gruppe hinzufügen",
      },
      deleteButton: {
        icon: "delete",
        tooltip: "Nutzer entfernen",
      } as IRowButton,
      isLoading: true,
      target: {
        index: 0,
        row: null as IRow | null,
        user: null as IUserResult | null,
      },
      currentlyLoading: [] as number[],
    };
  },
  async created() {
    this.openInviteByRouteQuery();
  },
  computed: {
    inviteOverlay(): IMdOverlay {
      return this.$refs.inviteOverlay as IMdOverlay;
    },
    users(): IUserResult[] {
      return this.store.getters[AdminStore.Getters.GET_USERS];
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      this.users.forEach((user: IUserResult, index: number) => {
        let permissions = "Keine erteilt";
        if (user.permissions.length > 0)
          permissions = user.permissions.length + " Stück";
        rows.push({
          id: user.id,
          image: user.image,
          name: user.name,
          email: user.email,
          permissions,
          add_to_group: this.addToGroupButton,
          delete: this.deleteButton,
          user: user,
          _loading: this.currentlyLoading.includes(index),
        });
      });
      return rows;
    },
  },
  watch: {
    "$route.query"() {
      this.openInviteByRouteQuery();
    },
    users() {
      this.isLoading = false;
      this.currentlyLoading = [];
    },
  },
  methods: {
    loadUsers() {
      this.store.dispatch(AdminStore.Actions.LOAD_USERS);
    },
    redirectToUser(row: IRow, index: number) {
      this.$router.push({
        path: "/admin/users/" + row.id,
      });
    },
    handleTableButtonClick(columnName: string, row: IRow, index: number) {
      this.target.index = index;
      this.target.row = row;
      this.target.user = row.user;
      switch (columnName) {
        case "add_to_group":
          (this.$refs.addToGroupModal as IMdModal).open();
          break;
        case "delete":
          this.openDeleteModal();
          break;
      }
    },
    openDeleteModal() {
      (this.$refs.deleteModal as IMdModal).open();
    },
    closeDeleteModal() {
      this.target.user = null;
      (this.$refs.deleteModal as IMdModal).close();
    },
    async deleteUser() {
      this.closeDeleteModal();
      const index = this.target.index;
      if (this.target.row?.id) {
        this.currentlyLoading.push(index);
        await deleteUser(this.target.row.id);
        this.loadUsers();
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Benutzer gelöscht",
        });
      }
    },
    openInviteByRouteQuery() {
      if (this.$route.query.invite == "1") {
        this.inviteOverlay.open();
        this.$router.replace({ query: {} });
      }
    },
  },
});
