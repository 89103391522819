
import { defineComponent } from "vue";
import { MdOverlay, MdOverlayComponentMixin } from "@/components/md/MdOverlay";
import { MdInputField } from "@/components/md/MdInput";
import { MdButton } from "@/components/md/MdButton";
import { addOrganization } from "@/api/admin/organization";
import { onLoadGetElements } from "@braks/vue-flow/dist/utils";

export default defineComponent({
  name: "OrganizationAddOverlay",
  mixins: [MdOverlayComponentMixin],
  components: {
    MdOverlay,
    MdInputField,
    MdButton,
  },
  methods: {
    async addOrganization() {
      this.setLoading(true);
      try {
        const res = await addOrganization(this.orgName);
        console.log("RESULT:", res);
      } catch (err) {
        console.log("Error: ", err);
      }
      this.setLoading(false);
      this.close();
      // TODO redirect after creating organization
      // this.$router.push()
    },
  },
  data() {
    return {
      orgName: "",
    };
  },
});
