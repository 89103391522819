
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { IPaymentMethodResult } from "@chatcaptain/types/billing";
import { deletePaymentMethod } from "@/api/admin/billing";
import { useStore } from "@/store";
import { AdminStore, RootStore } from "@/store/constants";

export default defineComponent({
  name: "BillingDeletePaymentMethodModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdButton,
  },
  props: {
    method: Object as PropType<IPaymentMethodResult>,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    async deleteMethod() {
      this.setLoadingState(true);
      try {
        if (this.method) await deletePaymentMethod(this.method.id);
        this.store.dispatch(AdminStore.Actions.LOAD_PAYMENT_METHODS);
        this.close();
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Zahlungsmethode gelöscht",
        });
      } catch (err) {
        console.error("err: ", err);
        this.close();
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Zahlungsmethode konnte nicht gelöscht werden",
        });
      }
      this.setLoadingState(false);
    },
  },
});
