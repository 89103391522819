import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f49c81e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_card = _resolveComponent("md-card")!

  return (_openBlock(), _createBlock(_component_md_card, {
    variant: "outlined",
    class: "payment-method"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.getLogo(_ctx.method.card?.brand))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "logo",
              style: _normalizeStyle({
          backgroundImage: 'url(' + _ctx.getLogo(_ctx.method.card?.brand || 'sepa') + ')',
        })
            }, null, 4))
          : _createCommentVNode("", true),
        _createElementVNode("h2", null, "**** " + _toDisplayString(_ctx.method.card?.last4 || _ctx.method.sepa?.last4), 1)
      ]),
      _createElementVNode("h3", null, _toDisplayString(_ctx.method.holderName), 1),
      (_ctx.method.type == 'card')
        ? (_openBlock(), _createElementBlock("h5", _hoisted_2, "Gültig bis " + _toDisplayString(_ctx.expire), 1))
        : (_openBlock(), _createElementBlock("h5", _hoisted_3, "SEPA Lastschriftverfahren")),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_md_button, {
          variant: "text",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-edit')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Bearbeiten")
          ]),
          _: 1
        }),
        _createVNode(_component_md_button, {
          variant: "icon",
          grey: "",
          icon: "delete",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click-delete')))
        })
      ])
    ]),
    _: 1
  }))
}