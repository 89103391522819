import { renderSlot as _renderSlot, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "cc-step",
    key: _ctx.index
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ])), [
    [_vShow, _ctx.index == _ctx.selectedIndex]
  ])
}