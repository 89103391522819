
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { IGroupResult } from "@chatcaptain/types/organization";
import { MdSelect } from "@/components/md/MdInput";
import { IMenuItem } from "@/components/md/MdMenu";
import { useStore } from "@/store";
import { AdminStore, RootStore } from "@/store/constants";
import { InputType, InputValidator } from "@/classes/InputValidator";
import { addMember } from "@/api/admin/groups";
import { ISnackbarData } from "../md/MdSnackbar";

export default defineComponent({
  name: "UserAddGroupModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdSelect,
    MdButton,
  },
  props: {
    group: {
      type: Object as PropType<IGroupResult>,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    store.dispatch(AdminStore.Actions.LOAD_USERS);
    return { store };
  },
  data() {
    return {
      user: "",
      error: "",
      groupModalLoading: false,
    };
  },
  computed: {
    users(): IMenuItem[] {
      const items: IMenuItem[] = [];
      const users = this.store.getters[AdminStore.Getters.GET_USERS];
      console.log("users: ", users);
      if (users)
        users.forEach((user) => {
          items.push({
            label: user.name,
            value: user.id,
          });
        });
      return items;
    },
    inputValidator(): InputValidator {
      return new InputValidator(this.user, InputType.TEXT, true);
    },
  },
  methods: {
    async addToGroup() {
      if (!this.inputValidator.isValid()) {
        this.error = "Du musst einen Benutzer auswählen";
        return;
      }
      this.groupModalLoading = true;
      await addMember(this.group.id, this.user);
      const user = this.store.getters[AdminStore.Getters.GET_USER](this.user);
      if (user) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: user.name + " hinzugefügt",
        } as ISnackbarData);
      }
      this.groupModalLoading = false;
      this.close();
      this.user = "";
      this.$emit("submit");
    },
  },
});
