
import { defineComponent } from "vue";
import { MdButton } from "@/components/md/MdButton";
import { MdCard } from "@/components/md/MdCard";
import { EmptyHint } from "@/components/cc/EmptyHint";
import { MdOverlay } from "@/components/md/MdOverlay";
import OverlayImage from "@/assets/images/overlay-payment-method.svg";
import BillingAddPaymentMethodOverlay from "@/components/admin/BillingAddPaymentMethodOverlay.vue";
import PaymentMethod from "@/components/admin/PaymentMethod.vue";
import PaymentMethodLoader from "@/components/admin/PaymentMethodLoader.vue";
import BillingDeletePaymentMethodModal from "@/components/admin/BillingDeletePaymentMethodModal.vue";
import BillingEditPaymentMethodModal from "@/components/admin/BillingEditPaymentMethodModal.vue";
import { IPaymentMethodResult } from "@chatcaptain/types/billing";
import { useStore } from "@/store";
import { AdminStore } from "@/store/constants";
import { IMdModal } from "@/components/md/MdDialog";

export default defineComponent({
  name: "AdminBillingPayment",
  components: {
    MdButton,
    MdCard,
    EmptyHint,
    MdOverlay,
    BillingAddPaymentMethodOverlay,
    PaymentMethod,
    PaymentMethodLoader,
    BillingDeletePaymentMethodModal,
    BillingEditPaymentMethodModal,
  },
  data() {
    return {
      addMethodVisible: false,
      addMethodLoading: false,
      OverlayImage,
      targetMethod: null as IPaymentMethodResult | null,
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    methods(): IPaymentMethodResult[] {
      return this.store.getters[AdminStore.Getters.GET_PAYMENT_METHODS];
    },
  },
  methods: {
    loadPaymentMethods() {
      this.store.dispatch(AdminStore.Actions.LOAD_PAYMENT_METHODS);
    },
    openDeleteModal(method: IPaymentMethodResult) {
      this.targetMethod = method;
      (this.$refs.deleteMethodModal as IMdModal).open();
    },
    openEditModal(method: IPaymentMethodResult) {
      this.targetMethod = method;
      (this.$refs.editMethodModal as IMdModal).open();
    },
  },
});
