
import { defineComponent, PropType } from "vue";
import { MdCard } from "@/components/md/MdCard";
import Clickable from "../mixins/Clickable";
import { IChatbotResult } from "@/api/bot";

export default defineComponent({
  name: "ChatbotCard",
  mixins: [Clickable],
  components: {
    MdCard,
  },
  props: {
    chatbot: {
      type: Object as PropType<IChatbotResult>,
      required: true,
    },
  },
});
