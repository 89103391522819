import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45883ba1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "users" }
const _hoisted_2 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_tabs = _resolveComponent("md-tabs")!
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_empty_hint = _resolveComponent("empty-hint")!
  const _component_delete_user_modal = _resolveComponent("delete-user-modal")!
  const _component_user_add_group_modal = _resolveComponent("user-add-group-modal")!
  const _component_users_invite_overlay = _resolveComponent("users-invite-overlay")!
  const _component_md_fab_extended = _resolveComponent("md-fab-extended")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_tabs, {
      title: "Benutzer",
      "title-only": ""
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoading || (_ctx.rows && _ctx.rows.length > 0))
        ? (_openBlock(), _createBlock(_component_md_table, {
            key: 0,
            rows: _ctx.rows,
            clickable: true,
            onClick: _ctx.redirectToUser,
            onButtonClick: _ctx.handleTableButtonClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_table_column, {
                type: "avatar",
                title: "Bild",
                display: "image"
              }),
              _createVNode(_component_md_table_column, {
                title: "Name",
                display: "name"
              }),
              _createVNode(_component_md_table_column, {
                title: "E-Mail-Adresse",
                display: "email"
              }),
              _createVNode(_component_md_table_column, {
                title: "Berechtigungen",
                display: "permissions",
                numeric: true
              }),
              _createVNode(_component_md_table_column, {
                title: "",
                type: "button",
                display: "add_to_group"
              }),
              _createVNode(_component_md_table_column, {
                title: "",
                type: "button",
                display: "delete"
              })
            ]),
            _: 1
          }, 8, ["rows", "onClick", "onButtonClick"]))
        : (_openBlock(), _createBlock(_component_empty_hint, {
            key: 1,
            title: "Keine Benutzer gefunden",
            description: "Lade deine Kollegen ein, um mit diesen gemeinsam zu arbeiten."
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_button, {
                variant: "filled",
                icon: "person_add",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inviteUserVisible = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Benutzer hinzufügen")
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _createVNode(_component_delete_user_modal, {
      ref: "deleteModal",
      user: _ctx.target.user || undefined,
      onCancel: _ctx.closeDeleteModal,
      onSubmit: _ctx.deleteUser
    }, null, 8, ["user", "onCancel", "onSubmit"]),
    _createVNode(_component_user_add_group_modal, {
      ref: "addToGroupModal",
      user: _ctx.target.user
    }, null, 8, ["user"]),
    _createVNode(_component_users_invite_overlay, {
      ref: "inviteOverlay",
      onComplete: _ctx.loadUsers
    }, null, 8, ["onComplete"]),
    (_ctx.isLoading || (_ctx.rows && _ctx.rows.length > 0))
      ? (_openBlock(), _createBlock(_component_md_fab_extended, {
          key: 0,
          icon: "person_add",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inviteOverlay.open()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Benutzer hinzufügen")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}