
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdAvatar } from "@/components/md/MdAvatar";
import { MdButton } from "@/components/md/MdButton";
import { IUserResult } from "@chatcaptain/types/organization";

export default defineComponent({
  name: "UserDeleteModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdAvatar,
    MdButton,
  },
  props: {
    user: Object as PropType<IUserResult>,
  },
});
