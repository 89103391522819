import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44b1699d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delete-modal-group-preview" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_modal = _resolveComponent("md-modal")!

  return (_openBlock(), _createBlock(_component_md_modal, {
    ref: "modal",
    title: "Gruppe löschen?",
    icon: "delete_forever",
    variant: "delete"
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Wenn du diesen Benutzer löscht, verliert er den Zugriff auf diese Organisation und alle Inhalte darin. Außerdem kann er sich nicht mehr anmelden und auf ChatCaptain Dienste zugreifen. Du kannst diesen Benutzer zu jederzeit erneut zu dieser Organisation einladen. ")
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.group?.name), 1),
        (_ctx.group?.description)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.group.description), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_md_button, {
        variant: "text",
        grey: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Abbrechen")
        ]),
        _: 1
      }),
      _createVNode(_component_md_button, {
        variant: "filled",
        mode: "delete",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submit')))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Gruppe löschen")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}