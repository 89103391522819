import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e062047"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatbots" }
const _hoisted_2 = {
  key: 0,
  class: "content-wrapper"
}
const _hoisted_3 = {
  key: 0,
  class: "chatbots-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_tabs = _resolveComponent("md-tabs")!
  const _component_chatbot_card = _resolveComponent("chatbot-card")!
  const _component_chatbot_card_loader = _resolveComponent("chatbot-card-loader")!
  const _component_md_fab_extended = _resolveComponent("md-fab-extended")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_tabs, {
      title: "Chatbots",
      "title-only": ""
    }),
    (_ctx.isLoading || (_ctx.chatbots && _ctx.chatbots.length > 0))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.chatbots)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (!_ctx.isLoading && _ctx.chatbots.length > 0)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.chatbots, (chatbot) => {
                      return (_openBlock(), _createBlock(_component_chatbot_card, {
                        key: chatbot.id,
                        chatbot: chatbot,
                        onClick: ($event: any) => (_ctx.redirectToChatbot(chatbot.id))
                      }, null, 8, ["chatbot", "onClick"]))
                    }), 128))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(8, (key) => {
                      return _createVNode(_component_chatbot_card_loader, { key: key })
                    }), 64))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoading || (_ctx.chatbots && _ctx.chatbots.length > 0))
      ? (_openBlock(), _createBlock(_component_md_fab_extended, {
          key: 1,
          onClick: _ctx.importBotsTest
        }, {
          default: _withCtx(() => [
            _createTextVNode("Chatbots importieren")
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}