import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "users" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_tab = _resolveComponent("md-tab")!
  const _component_md_tabs = _resolveComponent("md-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_tabs, { title: "Abrechnung" }, {
      default: _withCtx(() => [
        _createVNode(_component_md_tab, {
          title: "Überblick",
          icon: "dashboard",
          name: "AdminBillingOverview"
        }),
        _createVNode(_component_md_tab, {
          title: "Rechnungen",
          icon: "receipt",
          name: "AdminBillingInvoices"
        }),
        _createVNode(_component_md_tab, {
          title: "Zahlungsmethoden",
          icon: "payment",
          name: "AdminBillingPayment"
        }),
        _createVNode(_component_md_tab, {
          title: "Abonnement",
          icon: "subscriptions",
          name: "AdminBillingSubscription"
        })
      ]),
      _: 1
    })
  ]))
}