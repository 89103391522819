import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6123dc63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-add-group-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_select = _resolveComponent("md-select")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_modal = _resolveComponent("md-modal")!

  return (_openBlock(), _createBlock(_component_md_modal, {
    ref: "modal",
    title: "Benutzer zu Gruppe hinzufügen",
    icon: "group_add",
    loading: _ctx.groupModalLoading
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Füge diesen Benutzer zu einer Gruppe hinzu, damit dieser alle Berechtigungen aus der Gruppe erhält. ")
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_md_select, {
          variant: "filled",
          label: "Gruppe auswählen",
          error: _ctx.error,
          items: _ctx.groups,
          modelValue: _ctx.group,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.group) = $event)),
          searchable: true
        }, null, 8, ["error", "items", "modelValue"])
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_md_button, {
        variant: "text",
        grey: "",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Abbrechen")
        ]),
        _: 1
      }),
      _createVNode(_component_md_button, {
        variant: "filled",
        onClick: _ctx.addToGroup
      }, {
        default: _withCtx(() => [
          _createTextVNode("Zu Gruppe hinzufügen")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["loading"]))
}