
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { IPaymentMethodResult } from "@chatcaptain/types/billing";
import { useStore } from "@/store";
import { AdminStore, RootStore } from "@/store/constants";
import { MdInputField, MdSelect } from "@/components/md/MdInput";
import { IMenuItem } from "../md/MdMenu";
import { InputType, InputValidator } from "@/classes/InputValidator";
import { updatePaymentMethod } from "@/api/admin/billing";

export default defineComponent({
  name: "BillingEditPaymentMethodModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdButton,
    MdInputField,
    MdSelect,
  },
  props: {
    method: Object as PropType<IPaymentMethodResult>,
  },
  data() {
    return {
      company: this.method?.company || "",
      address: {
        street: this.method?.address.street || "",
        postcode: this.method?.address.postcode || "",
        city: this.method?.address.city || "",
        country: this.method?.address.country || "DE",
      },
      holderName: this.method?.holderName || "",
      vatId: this.method?.vat_id || "",
      countries: [
        {
          label: "Deutschland",
          value: "DE",
        },
        {
          label: "Österreich",
          value: "AT",
        },
        {
          label: "Schweiz",
          value: "CH",
        },
      ] as IMenuItem[],
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  watch: {
    method(val: IPaymentMethodResult) {
      this.company = val.company;
      this.address = val.address;
      this.holderName = val.holderName;
      this.vatId = val.vat_id || "";
    },
  },
  computed: {
    validators(): { [key: string]: InputValidator } {
      return {
        company: new InputValidator(this.company, InputType.TEXT, true),
        street: new InputValidator(this.address.street, InputType.TEXT, true),
        postcode: new InputValidator(
          this.address.postcode,
          InputType.TEXT,
          true
        ),
        city: new InputValidator(this.address.city, InputType.TEXT, true),
        country: new InputValidator(this.address.country, InputType.TEXT, true),
        vatId: new InputValidator(
          this.vatId,
          InputType.TEXT,
          this.vatIdRequired
        ),
        holderName: new InputValidator(this.holderName, InputType.TEXT, true),
      };
    },
    addressInputFields(): typeof MdInputField[] {
      return [
        this.$refs.companyInput as typeof MdInputField,
        this.$refs.streetInput as typeof MdInputField,
        this.$refs.postcodeInput as typeof MdInputField,
        this.$refs.cityInput as typeof MdInputField,
        this.$refs.countryInput as typeof MdInputField,
        this.$refs.vatIdInput as typeof MdInputField,
        this.$refs.holderNameInput as typeof MdInputField,
      ];
    },
    vatIdRequired(): boolean {
      return this.address.country !== "DE";
    },
  },
  methods: {
    async updateMethod() {
      this.addressInputFields.forEach((input) => input.validate());
      const isValid = Object.values(this.validators).every((validator) =>
        validator.isValid()
      );
      if (!isValid) return;
      this.setLoadingState(true);
      if (this.method)
        await updatePaymentMethod(
          this.method.id,
          this.company,
          this.address,
          this.holderName,
          this.vatId
        );
      this.setLoadingState(false);
      this.close();
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content: "Änderungen gespeichert",
      });
      this.store.dispatch(AdminStore.Actions.LOAD_PAYMENT_METHODS);
    },
  },
});
