
import { defineComponent, PropType, ref, provide, VNode } from "vue";

interface MdTabProps {
  title: string;
  icon?: string;
  name: string;
}
export default defineComponent({
  name: "MdTabsInline",
  props: {
    color: {
      type: String as PropType<
        "primary" | "secondary" | "tertiary" | "surface"
      >,
      required: false,
      default: () => {
        return "primary";
      },
    },
    mode: String as PropType<"normal" | "small">,
    align: String as PropType<"left" | "center" | "right">,
    title: String,
    titleOnly: Boolean,
    modelValue: String,
  },
  emits: ["update:modelValue"],
  setup(props, { slots }) {
    const defaultSlot = slots.default;
    if (defaultSlot) {
      const tabs = ref([] as MdTabProps[]);
      const tabValues = ref([] as string[]);
      ref(
        defaultSlot().forEach((tab: VNode) => {
          const props = tab.props as MdTabProps;
          tabs.value.push(props);
          tabValues.value.push(props.name);
        })
      );
      let selectedName = ref(tabValues.value[0]);
      if (props.modelValue)
        selectedName = ref(
          tabValues.value.filter((val) => val == props.modelValue)[0]
        );
      provide("selectedName", selectedName);
      return {
        tabs,
        tabValues,
        selectedName,
      };
    } else {
      return {
        tabs: null,
      };
    }
  },
  data() {
    return {
      transitionDirection: "left",
    };
  },
  methods: {
    tabClick(tab: MdTabProps) {
      if (tab.name) {
        const fromIndex =
          this.tabValues?.findIndex((name) => name == this.$route.name) || 0;
        const toIndex =
          this.tabValues?.findIndex((name) => name == tab.name) || 1;
        this.transitionDirection = fromIndex >= toIndex ? "left" : "right";
        this.selectedName = tab.name;
        this.$emit("update:modelValue", tab.name);
      }
    },
  },
});
