import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoices" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!
  const _component_empty_hint = _resolveComponent("empty-hint")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_table, {
      rows: _ctx.rows,
      onButtonClick: _ctx.downloadInvoice
    }, {
      default: _withCtx(() => [
        _createVNode(_component_md_table_column, {
          title: "Rechnungsnummer",
          display: "number"
        }),
        _createVNode(_component_md_table_column, {
          title: "Betrag",
          display: "amount",
          numeric: true
        }),
        _createVNode(_component_md_table_column, {
          title: "Status",
          display: "status",
          type: "badge"
        }),
        _createVNode(_component_md_table_column, {
          title: "Erstellt",
          display: "created"
        }),
        _createVNode(_component_md_table_column, {
          title: "",
          display: "download",
          type: "button"
        })
      ]),
      _: 1
    }, 8, ["rows", "onButtonClick"]),
    false
      ? (_openBlock(), _createBlock(_component_empty_hint, {
          key: 0,
          title: "Noch keine Rechnungen vorhanden",
          description: "Rechnungen werden hier angezeigt, sobald du ein Abonnement hinzugefügt hast."
        }))
      : _createCommentVNode("", true)
  ]))
}